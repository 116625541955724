<template>
  <div class="home page-wrapper">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
              {{ $auth.activeCompany.companyName }}
            </div>
            <h2 class="page-title">Performance Report</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl" v-if="loaded">
        <div
          class="row mb-3"
          v-if="!canAccess('performance-report', $auth.highestPlan)"
        >
          <div class="col">
            <upgrade-to-access>
              <p>
                Upgrade to a featured listing to unlock full access to your
                profile performance.
              </p>
            </upgrade-to-access>
          </div>
        </div>
        <div v-else class="row row-deck row-cards">
          <div class="col col-lg-3">
            <form action="" method="get">
              <site-select
                :all-sites="sites_map"
                v-on:update:selected="updateSelectedSites"
              ></site-select>

              <date-filters
                :all-filters="date_filters"
                :bus="this.filter.selected_datefilter"
                v-on:update:selected="updateSelectedDateFilter"
              ></date-filters>

              <div v-if="custom">
                <date-select
                  :label="`Start Date`"
                  v-on:update:selected="updateSelectedStartDate"
                ></date-select>

                <date-select
                  :label="`End Date`"
                  v-on:update:selected="updateSelectedEndDate"
                ></date-select>
              </div>

              <div v-if="$auth.ignoreDemo.isFeatured">
                <div class="subheader mb-2">Export</div>
                <div class="mb-3">
                  <button
                    class="btn btn-primary w-100"
                    @click.prevent="generateCSV()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-table-export"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M11.5 20h-5.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v7.5m-16 -3.5h16m-10 -6v16m4 -1h7m-3 -3l3 3l-3 3"
                      ></path>
                    </svg>
                    Download as CSV
                  </button>
                </div>
              </div>
              <div v-if="reload">
                <div class="spinner-border mt-2" role="status"></div>
              </div>
            </form>
          </div>
          <div class="col-lg-9">
            <div class="container-xl" v-if="performanceDTOs.length > 0">
              <div class="row">
                <div class="col">
                  <div class="d-flex justify-content-end">
                    <div class="subheader mb-2" id="topOftable">
                      Showing {{ totalFilteredAdPlacements }} out of
                      {{ this.total_adplacements }} Ad Placements
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="table-responsive">
                      <table
                        class="table table-vcenter card-table table-striped"
                      >
                        <thead>
                          <tr>
                            <th>Placement</th>
                            <th class="text-center">Views</th>
                            <th class="text-center">Actions</th>
                            <th class="text-center">Action Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="performanceDTO in displayPerformanceDTOs"
                            v-bind:key="performanceDTO.rowid"
                            :class="[
                              performanceDTO.type == '' && !performanceDTO.refid
                                ? 'highlight'
                                : '',
                            ]"
                          >
                            <td>
                              <div
                                :class="
                                  getPlacementClass(
                                    performanceDTO.type,
                                    performanceDTO.refId
                                  )
                                "
                              >
                                {{ performanceDTO.path }}
                              </div>
                            </td>
                            <td>
                              <div
                                class="text-center"
                                :class="[
                                  performanceDTO.type == '' &&
                                  !performanceDTO.refid
                                    ? 'font-weight-bold'
                                    : '',
                                ]"
                              >
                                {{ performanceDTO.impressions }}
                              </div>
                            </td>
                            <td>
                              <div
                                class="text-center"
                                :class="[
                                  performanceDTO.type == '' &&
                                  !performanceDTO.refid
                                    ? 'font-weight-bold'
                                    : '',
                                ]"
                              >
                                {{ performanceDTO.actions }}
                              </div>
                            </td>
                            <td>
                              <div
                                class="text-center"
                                :class="[
                                  performanceDTO.type == '' &&
                                  !performanceDTO.refid
                                    ? 'font-weight-bold'
                                    : '',
                                ]"
                              >
                                {{ performanceDTO.actionRate }}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="text-right font-weight-bold">
                                Total
                              </div>
                            </td>
                            <td>
                              <div class="text-center font-weight-bold">
                                {{ total_views }}
                              </div>
                            </td>
                            <td>
                              <div class="text-center font-weight-bold">
                                {{ total_clicks }}
                              </div>
                            </td>
                            <td>
                              <div class="text-center font-weight-bold">
                                {{ total_actionrate }}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-xl" v-else>
              No traffic exists for the dates selected.
            </div>
          </div>
        </div>
      </div>
      <div class="container-xl" v-else>
        <div class="row">
          <div class="col">
            <h3>Preparing performance report...</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.highlight {
  background-color: rgb(252, 252, 192) !important;
}

.table-striped > tbody > tr:nth-of-type(2n).highlight {
  --tblr-table-accent-bg: rgb(252, 252, 192) !important;
}

.table .text-right {
  text-align: right;
}
.table .text-left {
  text-align: left;
}
.table .text-center {
  text-align: center;
}
.table-responsive {
  margin-bottom: 0px !important;
  font-size: 0.875rem;
}

.summaryrow {
  float: left;
  padding-left: 15px;
}

.summaryrow {
  float: left;
  padding-left: 15px;
}

.detailrow {
  float: left;
  padding-left: 30px;
}
</style>

<script>
import ReportService from "../../services/ReportService";
import CSVService from "../../services/CSVService";
import TrackingService from "../../services/TrackingService";
import SiteSelect from "../../components/Reports/VisitorInsight/SiteSelect.vue";
import DateFilters from "../../components/Reports/DateFilters.vue";
import DateSelect from "../../components/Reports/DateSelect.vue";
import UpgradeToAccess from "../../components/Reports/UpgradeToAccess.vue";
import UpsellService from "../../services/UpsellService";

import { subDays, add } from "date-fns";

export default {
  name: "PerformanceReport",
  components: {
    SiteSelect,
    DateFilters,
    DateSelect,
    UpgradeToAccess,
  },
  data: () => {
    return {
      loaded: false,
      reload: false,
      performanceDTOs: [],
      sites_map: {},
      total_views: 0,
      total_clicks: 0,
      total_actionrate: 0.0,
      date_filters: [
        "Last 12 Months",
        "Year To Date",
        "Month To Date",
        "Today",
        "Custom",
      ],
      custom: false,
      rows: 0,
      total_adplacements: 0,
      total_filteredadplacements: 0,
      filter: {
        selected_categories: [],
        selected_sites: [],
        selected_datefilter: "",
        selected_startdate: "",
        selected_enddate: "",
      },
    };
  },
  methods: {
    canAccess(feature, plan) {
      return UpsellService.planIncludes(feature, plan);
    },
    generateCSV() {
      TrackingService.track("download performance report csv");

      let fields = [
        {
          id: "Path",
          getter: (r) => r["path"],
        },
        {
          id: "Views",
          getter: (r) => r["impressions"],
        },
        {
          id: "Clicks",
          getter: (r) => r["actions"],
        },
        {
          id: "Click_Through_Rate",
          getter: (r) => r["actionRate"],
        },
      ];

      let totalPerformanceDTO = {
        rowid: null,
        siteid: null,
        siteName: null,
        type: null,
        refId: null,
        impressions: this.total_views,
        actions: this.total_clicks,
        actionRate: this.total_actionrate,
        path: "Total",
        sort: null,
        sortirank: null,
        sitearank: null,
        sitearrank: null,
        iRank: null,
        aRank: null,
        arrank: null,
        idrank: null,
        adrank: null,
        ardrank: null,
      };

      let data = this.filteredPerformanceDTOs;
      data.push(totalPerformanceDTO);

      let csv = CSVService.serialize({
        fields: fields,
        records: data,
      });

      let download = document.createElement("a");
      let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      let url = URL.createObjectURL(blob);
      download.href = url;
      download.setAttribute("download", "performance_export.csv");
      download.click();
    },
    updateSelectedSites(selected) {
      this.$set(this.filter, "selected_sites", selected);

      TrackingService.track("change performance report site filters");

      // clear
      this.total_views = 0;
      this.total_clicks = 0;
      this.total_actionrate = 0.0;
      this.total_filteredadplacements = 0;

      // iterate through all FILTERED placement rows
      this.performanceDTOs.forEach((n) => {
        if (
          this.filter.selected_sites.indexOf(n.siteName) >= 0 ||
          this.filter.selected_sites.length == 0
        ) {
          // Only keep running count of site row totals
          if (n.sort == 1) {
            // keep running view total
            this.total_views += n.impressions;

            // keep running view total
            this.total_clicks += n.actions;
          } else if (n.sort > 2) {
            this.total_filteredadplacements++;
          }
        }
      });

      // calculate total actionrate
      this.total_actionrate =
        this.total_clicks / (this.total_views == 0 ? 1 : this.total_views);

      // format total actionrate
      this.total_actionrate =
        (Number((this.total_actionrate * 100).toFixed(2)) == 0.0
          ? "0.00"
          : Number((this.total_actionrate * 100).toFixed(2))) + "%";
    },
    updateSelectedDateFilter(selected) {
      // set the selected datefilter
      this.$set(this.filter, "selected_datefilter", selected);

      // set custom flag
      this.custom = selected == "Custom" ? true : false;

      TrackingService.track("change performance report date filters");

      // set appropriate dates for filter
      switch (selected) {
        case "":
          this.$set(this.filter, "selected_startdate", "");
          this.$set(this.filter, "selected_enddate", "");
          break;
        case "Year To Date":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(new Date(new Date().getFullYear(), 0, 1))
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        case "Last 12 Months":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(add(new Date(), { years: -1 }))
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        case "Last 30 Days":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(subDays(new Date(), 30))
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(subDays(new Date(), 1))
          );
          break;
        case "Month To Date":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(
              new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            )
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        case "Today":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(new Date())
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        default:
          break;
      }

      // load ad data for company
      this.getPerformanceData(true);
    },
    updateSelectedStartDate(selected) {
      this.$set(this.filter, "selected_startdate", selected);
      if (this.filter.selected_enddate != "") {
        this.getPerformanceData(true);
      }
    },
    updateSelectedEndDate(selected) {
      this.$set(this.filter, "selected_enddate", selected);
      this.getPerformanceData(true);
    },
    getFormattedDate(thedate) {
      let year = new Date(thedate).getFullYear();
      let month = (1 + new Date(thedate).getMonth())
        .toString()
        .padStart(2, "0");
      let day = new Date(thedate).getDate().toString().padStart(2, "0");
      return month + "/" + day + "/" + year;
    },
    getPlacementClass(type, refid) {
      let className = "";

      if (type == "" && !refid) {
        className = "font-weight-bold";
      } else if (type && !refid) {
        className = "summaryrow";
      } else if (type == "fl" && refid) {
        className = "detailrow";
      } else if (type == "ad" && refid) {
        className = "summaryrow";
      }

      return className;
    },
    async getPerformanceData(isreload) {
      let company = this.$auth.activeCompany;

      let reportFilter = {
        Company: { Companyid: company.companyId },
        StartDate: this.filter.selected_startdate,
        EndDate: this.filter.selected_enddate,
      };

      // mark as true reload or not
      this.reload = isreload;

      // make service call
      let performanceDTOs = await ReportService.getPerformanceReport(
        reportFilter
      );

      // clear
      this.total_views = 0;
      this.total_clicks = 0;
      this.total_actionrate = 0.0;
      this.total_filteredadplacements = 0;
      this.total_adplacements = 0;
      this.sites_map = {};

      performanceDTOs.forEach((n) => {
        // incrment rows
        this.rows++;

        // build rowid
        n.rowid = this.rows;

        // prepare image
        n.image = n.image
          ? process.env.VUE_APP_CLIENT_IMAGE_URL + n.image
          : n.image;

        // Only keep running count of site row totals
        if (n.sort == 1) {
          // keep running view total
          this.total_views += n.impressions;

          // keep running view total
          this.total_clicks += n.actions;
        } else if (n.sort > 2) {
          this.total_adplacements++;

          // populate available sites returned from output
          if (!this.sites_map[n.siteName]) {
            this.sites_map[n.siteName] = 0;
          }
          this.sites_map[n.siteName] += 1;
        }

        // format action rate
        n.actionRate =
          (Number((n.actionRate * 100).toFixed(2)) == 0.0
            ? "0.00"
            : Number((n.actionRate * 100).toFixed(2))) + "%";
      });

      // sort by sitename
      this.performanceDTOs = performanceDTOs.sort((a) => a.siteName);

      // calculate total actionrate
      this.total_actionrate =
        this.total_clicks / (this.total_views == 0 ? 1 : this.total_views);

      // format total actionrate
      this.total_actionrate =
        (Number((this.total_actionrate * 100).toFixed(2)) == 0.0
          ? "0.00"
          : Number((this.total_actionrate * 100).toFixed(2))) + "%";

      // mark as loaded
      this.loaded = true;

      // mark as reloaded
      this.reload = false;
    },
  },
  computed: {
    filteredPerformanceDTOs() {
      return this.performanceDTOs.filter((n) => {
        if (this.filter.selected_sites.length == 0) return true;
        return this.filter.selected_sites.indexOf(n.siteName) >= 0;
      });
    },
    displayPerformanceDTOs() {
      return this.filteredPerformanceDTOs;
    },
    totalFilteredAdPlacements() {
      return this.total_filteredadplacements > 0
        ? this.total_filteredadplacements
        : this.total_adplacements;
    },
  },
  async mounted() {
    if (this.$route.query.filter) {
      this.updateSelectedDateFilter("Last 30 Days");
    } else {
      this.getPerformanceData(false);
    }

    TrackingService.track("viewed performance report");
  },
};
</script>
